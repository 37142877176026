.backgroundArticles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0 90px;
  margin-left: 30px;

  .draftsHeader {
    display: flex;
    justify-content: space-between;

    h1 {
      margin-left: 20px;
      color: white;
    }

    .createButton {
      position: fixed;
      left: 90%;
      bottom: 5%;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      cursor: pointer;

      div {
        font-size: 30px;
        color: black;
      }
    }
  }

  .draftParameters {
    display: flex;
    margin: 0px 0 10px 10px;
    justify-content: space-between;

    .parametersButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 5px 10px;
      border: 1px solid var(--word-bg);
      background-color: var(--word-bg);

      .parametersActive {
        position: absolute;
        margin-left: 105px;
        margin-bottom: 10px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: red;
      }

      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      p {
        margin: auto 0;
        font-size: 18px;
        color: rgb(192, 192, 192);
      }
    }

    .parametersMenu {
      background-color: var(--word-bg);
    }
  }

  .articlesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .test {
    justify-self: flex-end;
  }
}

.clearParameters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: 600;

  svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 700px) {
  .backgroundArticles {
    padding: 15px 0 0 0px;
    margin-left: 0px;

    .draftsHeader {
      margin-left: 30px;
      .createButton {
        left: 80%;
      }
    }

    .draftParameters {
      margin-left: 10px;
      .parametersButton {
        svg {
          margin-right: 5px;
        }

        p {
          margin-right: 15px;
        }
      }
    }

    .articlesList {
      justify-content: center;
    }
  }
}
