.searchBlock{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;

    @media(max-width: 700px){
      width: 50%;
    }

    .clearInput{
        font-size: 20px;
        font-weight: 300;
        margin-right: 10px;
        cursor: pointer;
        position: absolute;
        color: grey;
        &:hover{
            color: red;
        }
    }
  }