.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--text-color);

  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  h1 {
    img {
      width: 35px;
      height: 35px;
    }
  }

  p {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .notFound {
    p {
      font-size: 14px;
    }
  }
}
