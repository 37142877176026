.usersBlock{
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 92px;

  .usersBlockHeader{
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 10px 0 ;
    h1{
      margin-left: 20px;
      margin-right: auto;
      color: white;
    }
  }

  .userTable {
    width: 100%;
    text-align: center;
  
    table {
      .firstColumn {
        @media (max-width: 700px) {
          display: none;
        }
      }
      thead {
        tr {
          th {
            color: #000;
          }
        }
      }
  
      tbody {
        .rolesBlock {
      display: flex;
          .backgroundTags {
        display: flex;
        flex-wrap: wrap;
  
        @media(max-width: 700px){
          width: 50%;
        }
          }
      .tagsMenu{
        margin: auto 0 auto auto;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
        }
        tr {
          border-style: none;
          .banBlock {
            color: #fff;
            background-color: #fa4251;
            margin: 0 10px;
            cursor: pointer;
          }
  
          @media (max-width: 700px) {
            select {
              background-image: none;
            }
          }
  
          .noBanBlock {
            color: #fff;
            background-color: #57b846;
            margin: 0 10px;
            cursor: pointer;
          }
        }
      }
    }
  
    .role{
      margin: 0 auto;
      background-color: var(--secondary-bg);
      border-radius: 10px;
      color: #fff;
      border: 1px solid var(--secondary-bg);
      padding: 10px 15px;
      margin: 5px auto;
      cursor: pointer;
  }
  }
}

@media(max-width: 700px){
  .usersBlock{
    padding: 0px;

    .usersBlockHeader{
      margin-left: 20px;
    }
  }
}
