.modalContent {
  background-color: var(--word-bg);
  color: var(--tertiary-bg);
  .modalHeader {
    border-bottom: 0;

    h1 {
      margin: auto;
    }
    .buttonClose {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modalFooter {
    display: flex;
    border-top: 0;
  }
}
