.tagBackground{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: var(--tertiary-bg);
    background-color: var(--main-container-bg);
    border: 1px solid var(--main-container-bg);
    border-radius: 100px;
    margin: 0px 5px;
    cursor: pointer;

    &:hover{
        background-color: var(--secondary-bg);
        color: var(--main-container-bg);

        .deleteButton{
            color: var(--main-container-bg);
        }
    }

    .deleteButton{
        color: var(--tertiary-bg);
        margin-left: 5px;
        &:hover{
            color: red;
        }
    }
}