.youtubeBlock {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px dotted white;

    svg{
      width: 30px;
      height: 30px;
      g{
        stroke: white;
      }
    }

    .youTubeInput {
      all: unset;
      width: 100%;
      color: white;
      font-size: 16px !important;
      font-weight: 800px;
      margin: 0 5px;
    }
  }