.backgroundModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  z-index: 2;

  .settingsModal {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-bg);
    padding: 20px;
    border-radius: 10px;

    @media (max-width: 1000px) {
      width: 70%;
    }

    @media (max-width: 700px) {
      width: 90%;
    }

    .settingsModalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: white;
      }

      .closeButton {
        font-size: 20px;
        cursor: pointer;
        color: white;

        &:hover {
          color: red;
        }
      }
    }

    .settingsModalContent {
      .draftSettings {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;

        .draftHead {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .titleField {
            color: white;

            @media (max-width: 700px) {
              margin-left: 100px;
            }
          }

          .slug {
            color: rgb(198, 198, 198);
          }
        }

        .filterParameter {
          background-color: white;
          padding: 5px 10px;
          border: 1px solid white;
          border-radius: 10px;
        }

        .banner {
          width: 50%;
          @media (max-width: 700px) {
            width: 90%;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          border: 1px dashed white;
          border-radius: 10px;
          margin-top: 10px;

          .bannerFile {
            padding: 5px 10px;
            border-radius: 10px;
            border: 1px solid var(--text-color);
            cursor: pointer;
            svg {
              width: 30px;
              height: 30px;
            }
          }

          .bannerFileInput {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }

          img {
            width: 100%;
          }

          .changeFileInput {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }

          .changeBannerPhotoButton {
            background-color: white;
            color: var(--secondary-bg);
            border-radius: 10px;
            padding: 10px 5px;
            position: absolute;
            align-self: flex-end;
            margin-right: 10px;
            margin-top: 10px;
            cursor: pointer;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .tagsBlock {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 90%;

          .allTags {
            width: 85%;
            margin: 10px 0;
            display: flex;

            &::-webkit-scrollbar {
              height: 7px;
              background-color: rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background: rgba(255, 255, 255, 0.5);
            }

            overflow-x: auto;
            white-space: nowrap;
          }

          .tagLabel{
            align-self: flex-start;
          }

          .searchTagInput {
            display: flex;
            background-color: none;
            border: 1px solid white;
            border-radius: 10px;
            padding: 5px;

            .tagLoading {
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                margin-right: 5px;
                width: 20px !important;
                height: 20px !important;
              }
            }

            input {
              all: unset;
              width: 100%;
              font-size: 16px !important;
            }

            @media (max-width: 700px) {
              width: 45%;
              input {
                width: 90%;
              }
            }

            svg {
              margin: auto;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }

          .draftTags {
            margin: 10px 0;
            display: flex;
          }
        }
      }
    }

    .settingsModalFooter {
      display: flex;
      justify-content: center;
      align-items: center;

      .editButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        padding: 5px 10px;
        margin-top: 15px;
        border: 1px solid var(--text-hover-color);
        background-color: var(--text-hover-color);
        color: var(--secondary-bg);

        span {
          width: 20px !important;
          height: 20px !important;
        }

        
        @media (max-width: 400px) {
          width: 50%;
        }

        &:hover {
          background-color: var(--secondary-bg);
          border: 1px solid var(--text-hover-color);
          color: var(--text-hover-color);
        }
      }
    }
  }
}
