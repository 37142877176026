.textBlock {
    display: flex;
    width: 100%;

    .textEditor{
      margin: 0 0 35px 0;
      width: 100%;
    }

    .textValue{
      align-self: center;
      span{
        color: rgba(194, 194, 194, 0.832);
      }
    }
  }