.editorSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .editorSettingsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: var(--main-bg);
    border: 1px solid var(--main-bg);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px 0;

    svg {
      margin-left: 10px;
      width: 25px;
      height: 25px;
    }
  }
}
