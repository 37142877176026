.pagination {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0px 10px 100px;
    font-size: 10px;


    button {
        display: flex;
        align-items: center;
        padding: 5px;
        justify-content: center;
        min-width: 20px;
        height: 20px;
        margin-inline-end: 8px;
        background-color: transparent;
        border: none;
        color: var(--text-color);
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: var(--secondary-bg);
            color: var(--text-hover-color);
          }

          &.active {
            color: var(--text-hover-color);
            border: 1px solid var(--text-hover-color);
            font-weight: 600;

            &:hover{
                background-color: transparent;
            }
          }
      }
  }
  