.pictureBlock {
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    margin: 0 auto;
    width: 40%;
    border-radius: 5px;

    @media(max-width: 700px){
      width: 70%;
    }
  }

  .pictureAlt {
    width: 50%;
    margin: 10px auto 0 auto;
    font-size: 10px;
    background-color: var(--word-bg);
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;

    @media(max-width: 700px){
      width: 100%;
      padding: 5px;
    }

    input {
      all: unset;
      font-size: 16px !important;
    }
  }
}
