.tools {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    z-index: 5;

    .toolBlock {
      display: flex;
      cursor: pointer;
      margin: 2px 0;
      color: black;
      font-size: 16px;

      svg {
        width: 25px;
        height: 25px;
      }

      .blockTitle {
        margin-left: 10px;
      }
    }

    .bannerFileInput {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }