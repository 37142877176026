.block {
  display: flex;
  margin: 10px 0;
  align-items: center;

  .addBlocksPlus {
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .dragDropIco {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s;
    cursor: hand;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .deleteBlockIco {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s;
    width: 30px;
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.464);
      color: red;
    }
  }

  &:hover {
    .dragDropIco {
      visibility: visible;
      opacity: 1;
    }

    .deleteBlockIco {
      visibility: visible;
      opacity: 1;
    }

    .addBlocksPlus {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: 1000px) {
  .block {
    .carouselBlock {
      .swiperBlock {
        .addCarouselPhotoButton {
          margin-left: 93%;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .block {
    .carouselBlock {
      .swiperBlock {
        .addCarouselPhotoButton {
          margin-left: 90%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .block {
    .carouselBlock {
      .swiperBlock {
        .addCarouselPhotoButton {
          margin-left: 85%;
        }
      }
    }
  }
}
