.quoteBlock {
    display: flex;
    flex-direction: column;
    width: 100%;

    .quoteTextArea {
      background-color: unset;
      width: 100%;
      border: 1px solid white;
      border-radius: 10px;
      resize: none;
      color: white;

      &:focus {
        outline: none;
      }
      font-size: 16px !important;
      font-weight: 400;
    }

    .sourcesBlock {
      display: flex;

      .quoteLine {
        display: flex;
        align-items: center;
        color: white;
        border: 1px solid white;
        border-radius: 10px;
        width: 50%;
        padding: 5px;
        margin: 5px 0;
        svg {
          width: 25px;
          height: 25px;
        }

        .quoteInput {
          all: unset;
          width: 100%;
          color: white;
          font-size: 16px !important;
          font-weight: 800px;
          margin: 0 5px;
        }
      }
    }
  }