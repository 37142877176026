.background {
  display: flex;
  flex-direction: column;
  color: white;

  .draftHead {
    display: flex;
    justify-content: space-between;
    margin-left: 100px;

    .titleField {
      width: 100%;
      color: white;

      &::before,
      &::after {
        display: none;
      }
    }

    .slug {
      color: grey;
    }

    .editorSettingsButton{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin: 20px 20px 0 0;
      padding: 5px;
      cursor: pointer;
      border: 1px solid var(--word-bg);
      color: white;

      svg{
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }

      &:hover{
        background-color: var(--word-bg);
        color: var(--text-color);
        svg{
          g{
            circle, path{
              stroke: var(--text-color);
            }
          }
        }
      }
    }
  }

  .backgroundEditor {
    display: flex;
    flex-direction: column;

    margin: 10px auto 30px auto;
    width: 80%;

    @media (max-width: 700px) {
      margin: 0;
      width: unset;
    }

    .blockTool {
      width: 100px;
      padding: 5px 0;
      border: 1px solid white;
      border-radius: 10px;
      margin-left: auto;

      .addBlockButton {
        cursor: pointer;
        padding: 0 10px;
        color: white;
      }
    }

    .normal-item {
      border: 1px solid black;
      margin: 5px;
      padding: 5px;
      display: flex;
      margin: 10px 0;
    }

    .dragged-item {
      border: 2px dashed red;
      margin: 5px;
      padding: 5px;
      display: flex;
      margin: 10px 0;
    }
  }

  input {
    width: 100%;
    font-size: 36px;
    font-weight: 600px !important;
  }

  .editButton {
    width: 15%;
    position: fixed;
    left: 40%;
    bottom: 0;
    margin-bottom: 5px;
    border: 1px solid var(--text-hover-color);
    background-color: var(--text-hover-color);
    color: var(--secondary-bg);

    @media (max-width: 700px) {
      width: 30%;
    }

    &:hover {
      background-color: var(--secondary-bg);
      border: 1px solid var(--text-hover-color);
      color: var(--text-hover-color);
    }
  }
}
