.backgroundArticleCard {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--main-container-bg);
  color: var(--text-color);
  border-radius: 10px;
  margin: 0 10px 10px 0;

  .draftHead {
    display: flex;
    justify-content: space-between;

    .statusBackground {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid grey;
      border-radius: 10px;
      padding: 2px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .editDraftIco {
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
      }

      .watchDraftIco {
        g{
          fill: var(--secondary-bg);
        }
        &:hover {
          g {
            fill: var(--text-color);
          }
        }
      }
    }
  }

  .cardImg {
    width: 270px;
    height: 150px;
    display: flex;
    justify-content: center;
    margin: 10px 0;
    margin-bottom: 10px;
    div {
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  .cardBody {
    display: flex;
    flex-direction: column;
    margin: 0 0 auto 0;
    justify-content: flex-start;

    .cardTitle {
      display: flex;
      justify-content: space-between;

      .titleContent {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        h4 {
          margin-right: 5px;
          text-decoration: underline var(--text-color) dotted;
        }
      }

      .draftSettingsIco {
        svg {
          width: 30px;
          height: 30px;
          cursor: pointer;
          g {
            fill: var(--text-color);
          }

          &:hover {
            g {
              fill: var(--secondary-bg);
            }
          }
        }
      }
    }

    .cardText {
      display: -webkit-box;

      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .cardButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;

    .cardButton {
      margin: 5px 0;

      svg {
        width: 20px;
        height: 20px;
        g {
          stroke: white;
        }
      }
    }

    .rightButtons {
      svg {
        cursor: pointer;
        margin: 0 0 0 5px;
        width: 20px;
        height: 25px;
      }

      .deleteButton {
        &:hover {
          g {
            fill: red;
          }
        }
      }
    }
  }
}
