.tagBackground{
    display: flex;
    background-color: var(--main-container-bg);
    border: 1px solid var(--secondary-bg);
    border-radius: 10px;
    padding: 3px;
    margin: 2px 1px;
    cursor: pointer;
    color: var(--tertiary-bg);

    .deleteButton{
        margin:0 5px 0 10px;
        color: var(--tertiary-bg);

        &:hover{
            color: red;
        }
    }
}