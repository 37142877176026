.filterParameters{
    display: flex;
    flex-direction: column;

    .filterParameter{
      background-color: white;
      padding: 10px 20px;
      border: 1px solid white;
      border-radius: 10px;
      margin: 0 10px 10px 10px;
    }
  }