.backgroundReviewPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0 90px;
  margin-left: 30px;

  .reviewHeader {
    display: flex;
    justify-content: space-between;

    h1 {
      color: white;
      margin-left: 20px;
    }
  }

  .reviewDrafts {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .draftParameters{
    display: flex;
    margin: 0px 0 10px 10px;
    justify-content: space-between;

    .parametersButton{
      display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 5px 10px;
        border: 1px solid var(--word-bg);
        background-color: var(--word-bg);

      .parametersActive{
        position: absolute;
        margin-left: 105px;
        margin-bottom: 10px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: red;
      }

      svg{
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      p{
        margin: auto 0;
        font-size: 18px;
        color: rgb(192, 192, 192);
      }
    }

    .parametersMenu{
      background-color: var(--word-bg);
    }
  }
}

.clearParameters{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: 600;

  svg{
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 700px) {
  .backgroundReviewPage {
    padding: 15px 0 0 0px;
    margin-left: 0px;

    .reviewHeader {
      margin-left: 30px;
    }

    .draftParameters{
      margin-left: 10px;
      .parametersButton{  
        svg{
          margin-right: 5px;
        }

        p{
          margin-right: 15px;
        }
      }
    }

    .reviewDrafts {
      justify-content: center;
    }
  }
}
