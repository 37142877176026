.carouselBlock {
  width: 40%;
  margin: 0 auto;

  .loadingBlock{
    background-color: rgba(253, 253, 253, 0.325);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
  }

  @media(max-width: 700px){
    width: 70%;
    margin: 0px;
  }

    .swiperBlock {
      display: flex;
      flex-direction: column;

      .pictureAlt {
        margin: 10px 0 25px 0;
        font-size: 10px;
        background-color: var(--word-bg);
        border: 1px solid grey;
        border-radius: 5px;
        padding: 10px;
    
        @media(max-width: 700px){
          width: 100%;
          padding: 5px;
        }
    
        input {
          all: unset;
          font-size: 16px !important;
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
      }

      .deleteCarouselPhoto {
        position: absolute;
        margin-top: 10px;
        margin-left: 10px;
        width: 30px;
        height: 30px;
        z-index: 1;

          &:hover{
            g{
              fill: red;
            }
          }
      }

      .addCarouselPhotoButton {
        position: absolute;
        padding: 5px 10px;
        margin-top: 10px;
        margin-right: 10px;
        align-self: flex-end;
        font-size: 26px;
        background-color: rgba(128, 128, 128, 0.366);
        border-radius: 10px;
        cursor: pointer;
        z-index: 1;

        &:hover {
          background-color: white;
          border-radius: 10px;
          color: black;
        }
      }

      .carouselInput {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }