.background{
    display: flex;
    flex-direction: column;

    text-align: center;

    left: 50%;
    top: 50%;
    position: absolute;
    transform : translate(-50%, -50%);

    color: white;

    p{
        color: rgb(188, 188, 188);
    }
}