.backgroundLogin{
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    color: var(--text-hover-color);
    border: 1px solid var(--text-hover-color);
    border-radius: 10px;

    left: 50%;
    top: 50%;
    position: absolute;
    transform : translate(-50%, -50%);

    .cardButton{
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            width: 20px !important;
            height: 20px !important;
          }
    }
}